import React, { useContext, useState } from 'react';

import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';
import s from './NavigationDesktop.module.scss';
import Link from 'next/link';
import Image from 'next/image';
import ButtonSecondary from '../ButtonSecondary';
import classNames from 'classnames';
import { Button } from 'ariakit/button';
import { Dialog, useDialogState } from 'ariakit/dialog';
import { GradientContext, ContactContext } from '../../containers/BasePage';
import FunFactDefault from '../../public/img/funfact_default.jpg';
import { trackMenuInteraction, trackMenuContact } from '../../utils/dataLayer';

const NavigationDesktop = ({
  navigation = [],
  navigationSpotlight = {},
  navigationDefaultImage = {},
  insights = {},
}) => {
  const contact = useContext(ContactContext);
  const { t } = useTranslation();
  const [openMenu, setOpenMenu] = useState(null);

  const handleMenuToggle = (menuType) => {
    setOpenMenu((prevMenu) => (prevMenu === menuType ? null : menuType));
  };

  return (
    <header className={`${s.Root} grid`}>
      <nav className={s.NavigationDesktop} aria-label="Menu">
        <Link href="/" legacyBehavior={true}>
          <a className={s.LogoLink}>
            <Image
              width={172}
              height={48}
              src="/img/logo.svg"
              alt="Stockholm Business Region Logotype - Go to startpage"
              className={s.Logo}
              loading="lazy"
            />
          </a>
        </Link>

        {navigation?.map((navigationItem, i) => {
          return (
            <NavigationDesktopItem
              key={i}
              navigationSpotlight={navigationSpotlight}
              navigationDefaultImage={navigationDefaultImage}
              openMenu={openMenu}
              handleMenuToggle={handleMenuToggle}
              insights={insights}
              {...navigationItem}
            />
          );
        })}

        {contact?.url && (
          <ButtonSecondary
            modifiers={['NavDesktop']}
            onClick={() => {
              trackMenuContact();
            }}
            asLink={true}
            href={contact?.url}>
            {t('Generic.contactUs')}
          </ButtonSecondary>
        )}
      </nav>
    </header>
  );
};

NavigationDesktop.propTypes = {
  navigation: PropTypes.array.isRequired,
};

NavigationDesktop.defaultProps = { navigation: [] };

const NavigationDesktopItem = ({
  title,
  href,
  children = [],
  isActive,
  isCurrent,
  navigationSpotlight = {},
  navigationDefaultImage = {},
  megaMenuType = 'none',
  openMenu,
  handleMenuToggle,
  insights = {},
}) => {
  const classes = classNames(s.NavigationDesktopLink, {
    [s.IsActive]: isActive,
  });

  return (
    <>
      {megaMenuType === 'none' ? (
        <Link href={href} legacyBehavior={true}>
          <a
            onClick={() => {
              trackMenuInteraction(1, title);
            }}
            className={classes}
            aria-current={isCurrent ? 'page' : false}>
            {title}
          </a>
        </Link>
      ) : megaMenuType === 'offer' ? (
        <OfferMegaMenu
          title={title}
          navigationSpotlight={navigationSpotlight}
          navigationDefaultImage={navigationDefaultImage}
          isActive={isActive}
          isCurrent={isCurrent}
          navChildren={children}
          isOpen={openMenu === title}
          handleMenuToggle={handleMenuToggle}
        />
      ) : (
        <InsightsMegaMenu
          title={title}
          navigationSpotlight={navigationSpotlight}
          navigationDefaultImage={navigationDefaultImage}
          isActive={isActive}
          isCurrent={isCurrent}
          navChildren={children}
          isOpen={openMenu === title}
          handleMenuToggle={handleMenuToggle}
          insights={insights}
        />
      )}
    </>
  );
};

NavigationDesktopItem.propTypes = {
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  children: PropTypes.array.isRequired,
  megaMenuType: PropTypes.string.isRequired,
  openMenu: PropTypes.string,
  handleMenuToggle: PropTypes.func.isRequired,
};

const OfferMegaMenu = ({
  title,
  isActive,
  isCurrent,
  navChildren = [],
  navigationSpotlight = {},
  navigationDefaultImage = {},
  isOpen,
  handleMenuToggle,
}) => {
  const { t } = useTranslation();
  const [currentFunFact, setCurrentFunFact] = useState();

  const dialog = useDialogState({ animated: true });
  const gradient = useContext(GradientContext);

  const chevronClasses = classNames(s.Chevron, {
    [s.ChevronOpen]: isOpen,
  });

  const megaMenuButtonClasses = classNames(s.MegaMenuButton, {
    [s.MegaMenuButtonOpen]: dialog.open,
    [s.IsActive]: isActive,
  });

  const wrapperClasses = classNames(s.MegaMenuWrapper, s[gradient], {
    [s.MegaMenuOpen]: isOpen,
  });

  const imageStyle = { objectFit: 'cover', maxHeight: 870 };

  if (
    !!navigationSpotlight?.page?.image &&
    !!navigationSpotlight?.page?.image.focal &&
    !!navigationSpotlight?.page?.image.focal.x &&
    !!navigationSpotlight?.page?.image.focal.y
  ) {
    imageStyle.objectPosition = `${navigationSpotlight.page.image.focal.x} ${navigationSpotlight.page.image.focal.y}`;
  }

  const funfacts = [
    {
      id: 'default',
      image: {
        url: navigationDefaultImage?.url
          ? navigationDefaultImage.url
          : FunFactDefault,
      },
    },
    ...navChildren,
  ];

  return (
    <>
      <Button
        className={megaMenuButtonClasses}
        aria-expanded={isOpen}
        aria-controls="mega-dialog"
        onClick={() => {
          trackMenuInteraction(1, title);
          handleMenuToggle(title);
          if (!isOpen) {
            dialog.toggle();
          } else {
            dialog.hide();
          }
        }}>
        {title}
        <span className={chevronClasses} />
      </Button>
      <Dialog state={dialog} id="mega-dialog" className={wrapperClasses}>
        <div className={s.BlobRight1} />
        <div className={s.BlobLeft1} />
        <div className={s.BlobCenter1} />
        <div className={`${s.MegaMenu} grid`}>
          <div className={s.ImageContent} style={imageStyle}>
            {!navigationSpotlight?.page?.url &&
              funfacts.map((child, i) => {
                const classes = classNames(s.FunFact, {
                  [s.FunFactVisible]: !!currentFunFact
                    ? currentFunFact === child.id
                    : funfacts[0].id === child.id,
                });
                return (
                  <div className={classes} key={child.id}>
                    <div className={s.ImageWrapper}>
                      <Image
                        className={s.Image}
                        src={child?.image?.url}
                        alt=""
                        fill
                        style={imageStyle}
                        loading="lazy"
                      />
                    </div>
                    {!!child.funFactTitle && (
                      <div className={`${s.ImageGlass} black-glass`}>
                        <div className={s.ImageTitle}>{child.funFactTitle}</div>
                        <div className={s.ImageText}>{child.funFactText}</div>
                      </div>
                    )}
                  </div>
                );
              })}
            {!!navigationSpotlight.page?.url && (
              <>
                <div className={s.ImageWrapper}>
                  <Image
                    className={s.Image}
                    src={navigationSpotlight?.page?.image?.url}
                    alt=""
                    fill
                    style={imageStyle}
                    loading="lazy"
                  />
                </div>
                <div className={`${s.ImageGlass} ${s.Spotlight} black-glass`}>
                  <div className={s.ImageTagline}>
                    {navigationSpotlight?.tagline}
                  </div>
                  <div className={s.ImageTitle}>
                    {navigationSpotlight?.title}
                  </div>
                  <div className={s.ImageText}>{navigationSpotlight?.text}</div>
                  {!!navigationSpotlight?.page?.url && (
                    <a
                      href={navigationSpotlight?.page?.url}
                      className={s.ImageLink}>
                      {navigationSpotlight?.page?.linkText}
                    </a>
                  )}
                </div>
              </>
            )}
          </div>
          <div className={`${s.MegaMenuItemsWrapper}`}>
            {navChildren.map((child, i) => (
              <MegaMenuItem
                key={i}
                {...child}
                dialog={dialog}
                setCurrentFunFact={setCurrentFunFact}
              />
            ))}
          </div>
          <button
            onClick={() => {
              handleMenuToggle(title);
              if (!isOpen) {
                dialog.toggle();
              } else {
                dialog.hide();
              }
            }}
            className={s.MegaMenuCloseButton}>
            {t('Generic.closeMenu')}
            <Image
              width={16}
              height={16}
              src={`/img/icon-cross-white.svg`}
              alt=""
            />
          </button>
        </div>
      </Dialog>
    </>
  );
};

OfferMegaMenu.propTypes = {
  title: PropTypes.string.isRequired,
  navChildren: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleMenuToggle: PropTypes.func.isRequired,
};

const InsightsMegaMenu = ({
  title,
  isActive,
  navChildren = [],
  isOpen,
  handleMenuToggle,
  insights = {},
}) => {
  const dialog = useDialogState({ animated: true });
  const gradient = useContext(GradientContext);
  const { t } = useTranslation();

  const imageStyle = {
    objectFit: 'cover',
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    opacity: 0.8,
  };

  const chevronClasses = classNames(s.Chevron, {
    [s.ChevronOpen]: isOpen,
  });

  const megaMenuButtonClasses = classNames(s.MegaMenuButton, {
    [s.MegaMenuButtonOpen]: dialog.open,
    [s.IsActive]: isActive,
  });

  const wrapperClasses = classNames(s.MegaMenuWrapper, s[gradient], {
    [s.MegaMenuOpen]: isOpen,
  });

  return (
    <>
      <Button
        className={megaMenuButtonClasses}
        aria-expanded={isOpen}
        aria-controls="mega-dialog-insights"
        onClick={() => {
          trackMenuInteraction(1, title);
          handleMenuToggle(title);
          if (!isOpen) {
            dialog.toggle();
          } else {
            dialog.hide();
          }
        }}>
        {title}
        <span className={chevronClasses} />
      </Button>
      <Dialog
        state={dialog}
        id="mega-dialog-insights"
        className={wrapperClasses}>
        <div className={s.BlobRight1} />
        <div className={s.BlobLeft1} />
        <div className={s.BlobCenter1} />
        <div className={`${s.MegaMenu} grid`}>
          <div className={s.LeftColumn}>
            <div className={s.ImageInsightsWrapper + ' black-glass'}>
              <Image
                className={s.ImageInsights}
                // src={'/img/insights-fallback-bg.webp'}
                src={'/img/insightsmegamenu.webp'}
                alt=""
                fill
                style={imageStyle}
                loading="lazy"
              />
              <div className={s.ImageContentInsights}>
                <h2 className={s.ImageTitleInsights}>{insights.title}</h2>
                <p className={s.ImageTextInsights}>{insights.text}</p>
                <ButtonSecondary
                  modifiers={['InlineFlex', 'IconHoverDown']}
                  icon="icon-arrow-right-white"
                  href={insights.url}>
                  {insights.buttonText || 'Read more'}
                </ButtonSecondary>
              </div>
            </div>
          </div>
          <div className={s.RightColumn}>
            <h2 className={s.MegaMenuInsightsTitle}>In depth insights</h2>
            <div className={`${s.MegaMenuItemsWrapper} ${s.Insights}`}>
              {navChildren.map((child, i) => (
                <MegaMenuItem key={i} {...child} dialog={dialog} />
              ))}
            </div>
            <button
              onClick={() => {
                handleMenuToggle(title);
                if (!isOpen) {
                  dialog.toggle();
                } else {
                  dialog.hide();
                }
              }}
              className={s.MegaMenuCloseButton}>
              {t('Generic.closeMenu')}
              <Image
                width={16}
                height={16}
                src={`/img/icon-cross-white.svg`}
                alt=""
              />
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

InsightsMegaMenu.propTypes = {
  title: PropTypes.string.isRequired,
  navChildren: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleMenuToggle: PropTypes.func.isRequired,
};

const MegaMenuItem = ({
  title,
  href,
  text,
  gradient,
  id,
  dialog,
  isCurrent,
  setCurrentFunFact,
}) => {
  const wrapClasses = classNames(s.MegaMenuItemWrapper, {
    [s[gradient]]: !!gradient,
  });

  return (
    <div
      onMouseOver={() => setCurrentFunFact && setCurrentFunFact(id)}
      onMouseOut={() => setCurrentFunFact && setCurrentFunFact(null)}
      className={wrapClasses}>
      <a
        aria-current={isCurrent ? 'page' : false}
        href={href}
        className={s.MegaMenuLink}
        onClick={() => {
          trackMenuInteraction(2, title);
          dialog.hide();
        }}>
        {title}
      </a>
    </div>
  );
};

MegaMenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};

export default NavigationDesktop;
